<template>
  <div class="notfound">
    <h1>404</h1>
    <h3>Página no encontrada.</h3>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.notfound {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.notfound > h1 {
  font-size: 4rem;
  color: var(--primary-dark);
}
  .notfound > h3 {
    font-size: 2rem;
    color: var(--primary-dark);
  }
</style>
